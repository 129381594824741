import PermissionService from '@/services/PermissionService'
import Config from '@/config'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_CONTENT_BLOCK_PERMISSIONS

const routes = [
  {
    path: 'contentBlock/new',
    component: () => Config.isVlm()
      ? import('@/views/contentBlock/vlm/ContentBlockNewView')
      : import('@/views/contentBlock/ContentBlockNewView'),
    name: 'contentBlock_new',
    meta: {
      description: 'ContentBlock new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'contentBlock/:id/edit',
    component: () => Config.isVlm()
      ? import('@/views/contentBlock/vlm/ContentBlockEditView')
      : import('@/views/contentBlock/ContentBlockEditView'),
    name: 'contentBlock_edit',
    meta: {
      description: 'ContentBlock edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'contentBlock/:id',
    component: () => Config.isVlm()
      ? import('@/views/contentBlock/vlm/ContentBlockView')
      : import('@/views/contentBlock/ContentBlockView'),
    name: 'contentBlock_detail',
    meta: {
      description: 'ContentBlock detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'contentBlock',
    component: () => Config.isVlm()
      ? import('@/views/contentBlock/vlm/ContentBlockListView')
      : import('@/views/contentBlock/ContentBlockListView'),
    name: 'contentBlock_list',
    meta: {
      description: 'ContentBlock list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
