import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.MENU_EDITOR_PERMISSIONS

const routes = [
  {
    path: 'menueditor/new',
    component: () => import('@/views/menueditor/MenuEditorNewView'),
    name: 'menueditor_new',
    meta: {
      description: 'MenuEditor new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'menueditor/:id/edit',
    component: () => import('@/views/menueditor/MenuEditorEditView'),
    name: 'menueditor_edit',
    meta: {
      description: 'MenuEditor edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'menueditor',
    component: () => import('@/views/menueditor/MenuEditorListView'),
    name: 'menueditor_list',
    meta: {
      description: 'MenuEditor list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
