import { dateTimeToday } from '@/services/DateTimeService'

export const HP_TOPIC_OFFER_MODEL_NMH = {
  site: 1,
  date: dateTimeToday(),
  department: 0
}

export const HP_TOPIC_OFFER_MODEL_VLM = {
  site: null,
  date: dateTimeToday(),
  contentBlock: null,
  department: 0
}
