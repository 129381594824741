import { dateTimeNow } from '@/services/DateTimeService'

const managedItemModel = {
  title: null,
  type: null,
  contentBlock: 0,
  position: 0,
  articleDocumentId: null,
  alternativeTitle: null,
  importance: null,
  orderDate: null,
  showUntil: null,
  isRegional: false,
  isSporting: false,
  seriesEntityUuid: null,
  specialArticleGroupEntityUuid: null,
  themeEntityUuid: null,
  videoEntityUuid: null,
  publishedSince: dateTimeNow(),
  publishedUntil: null,
  createdBy: 0
}

const singleItemModel = {
  theme: {
    id: null,
    name: null,
    themeEntityUuid: null,
    themeId: null,
    position: null
  },
  series: {
    id: null,
    name: null,
    seriesEntityUuid: null,
    seriesId: null,
    position: null
  },
  special: {
    id: null,
    name: null,
    specialArticleGroupEntityUuid: null,
    specialArticleGroupId: null,
    position: null
  }
}

export { managedItemModel, singleItemModel }
