import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'hpConfigurationOfWeights',
    component: () => import('@/views/hp/vlm/HpConfigurationOfWeightsView.vue'),
    name: 'hp_configuration_of_weights',
    meta: {
      description: 'HP configuration of weights',
      requiresAuth: true,
      requiredPermissionModules: PermissionService.REQUIRED_PERMISSIONS.MODULE_HOMEPAGE_CONFIGURATION_OF_WEIGHTS_PERMISSIONS.listRoute
    }
  }
]

export default routes
