export default {
  rubricId: 0, // id:
  site: null,
  hiddenInMenu: false,
  parent: null,
  title: '',
  sorting: 0,
  meta: {
    title: '',
    description: '',
    keywords: ''
  },
  setting: {
    advertCategory: '',
    advertSection: '',
    chartbeatSection: '',
    gemiusId: '',
    prRubric: false,
    includeInMenuSettings: false,
    special: false,
    showRating: false,
    showDiscussion: false
  },
  sportTable: {
    seasonId: 0,
    activeTab: 1
  },
  slug: '',
  textForSlug: '',
  routes: [],
  dataHubProductsIds: [],
  iptcCategories: [],
  rubricWithoutArticles: false,
  isVirtualized: false,
  isArticleToppingEnabled: false,
  heroImage: {},
  heroImageId: null,
  miniatureImage: {},
  miniatureImageId: null,
  description: '',
  groupHash: '',
  groupRubricIds: []
}
