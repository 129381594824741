export const SITE_ID_DENIK_CZ = 106

export default {
  id: 0,
  parentSiteId: null,
  isMasterSite: false,
  domain: '',
  url: '',
  entityUuid: '',
  createdAt: '',
  createdBy: 0,
  deletedAt: null,
  enabled: false,
  meta: {
    title: '',
    description: '',
    keywords: ''
  },
  modifiedAt: '',
  modifiedBy: 0,
  name: '',
  production: false,
  shortTitle: '',
  title: '',
  code: '',
  regionId: null,
  woodWing: {
    id: 0
  },
  showRating: false,
  showDiscussion: false
}
