export const ARTICLE_HP_TOPIC_OFFER_IMPORTANCE = [1, 2, 3, 4, 5]

export const ArticleHpTopicOfferAdditional = {
  articleImportance: null,
  preferredPosition: null,
  contentBlockId: '',
  publishedSince: null,
  publishedUntil: null,
  showUntil: null
}

export default {
  id: null,
  site: null,
  date: null,
  slot: null,
  approved: false,
  ...ArticleHpTopicOfferAdditional
}
