import { dateTimeNow } from '@/services/DateTimeService'

export default {
  id: null,
  siteId: null,
  title: '',
  isActive: false,
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdById: null,
  modifiedById: null,
  mainMenu: {
    rubrics: [],
    pinBoard: []
  }
}
