import { CONTENT_BLOCK_TYPE_ARTICLE } from '@/components/mixins/valueObject/ContentBlockTypeMixin'

export const CONTENT_BLOCK_FLAGS = {
  imageGallery: 'Photo Gallery',
  video: 'Video',
  podcast: 'Podcast',
  quiz: 'Quiz',
  poll: 'Poll',
  discussion: 'Discussion'
}

export const CONTENT_BLOCK_ARTICLE_TYPES = [
  { name: 'topArticlesFeed', label: 'Top Articles Feed' },
  { name: 'largeListing', label: 'Large Listing' },
  { name: 'largeListingWithMenu', label: 'Large Listing with Menu' },
  { name: 'verticalListing', label: 'Vertical Listing' },
  { name: 'horizontalListing', label: 'Horizontal Listing' },
  { name: 'chronologicalListing', label: 'Chronological Listing' }
]

export const CONTENT_BLOCK_VLM = {
  type: CONTENT_BLOCK_TYPE_ARTICLE,
  mainSettings: {
    hidden: false,
    position: 0,
    title: '',
    identifier: '',
    siteId: 0,
    siteName: '',
    category: {},
    isHomepage: true,
    items: {
      total: null,
      minRegional: null,
      maxSporting: null
    },
    contentBlockArticleType: '',
    buttonUrl: null,
    buttonName: null
  },
  contentSettings: {
    sitesIds: [],
    rubricsIds: [],
    tagsIds: [],
    geneeaTagsIds: [],
    authorSourcesIds: [],
    authorsIds: [],
    categoriesIds: [],
    queryDaysLimit: 0,
    articleType: {
      article: true,
      external: false,
      video: false,
      quiz: false,
      photostory: false
    },
    settings: {
      prArticle: false
    },
    flag: {
      video: false,
      imageGallery: false,
      podcast: false,
      quiz: false,
      poll: false,
      discussion: false
    }
  },
  createdById: 0
}

export const CONTENT_BLOCK_NMH = {
  type: CONTENT_BLOCK_TYPE_ARTICLE,
  ActionType: null,
  title: '',
  hidden: false,
  site: '',
  siteId: 0,
  category: '',
  identifier: '',
  settings: '',
  items: [],
  internalFallback: false,
  fallbackSite: null,
  fallbackRubrics: [],
  fallbackTags: [],
  fallbackCategories: [],
  fallbackFlag: {
    video: false,
    imageGallery: false
  },
  fallbackSetting: {
    prArticle: false
  },
  fallbackArticleType: {
    article: false,
    external: false,
    video: false,
    quiz: false,
    photostory: false
  },
  externalFallback: false,
  numberOfItems: 0,
  position: 0,
  displayPosition: 0,
  daysLimit: 365,
  disabledPositions: '0',
  fallbackPositions: '0',
  expanded: {
    fallbackRubrics: [],
    fallbackTags: [],
    fallbackCategories: []
  }
}
