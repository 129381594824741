import DateTimeService, { dateTimeNow } from '@/services/DateTimeService'
import moment from 'moment'
import { POLL_TYPE_DEFAULT } from '@/components/mixins/valueObject/PollTypeMixin'
import { CATEGORY_OTHER } from '@/model/ValueObject/PollCategory'

export default {
  id: null,
  site: null,
  title: '',
  description: '',
  type: POLL_TYPE_DEFAULT,
  category: CATEGORY_OTHER,
  orderDate: dateTimeNow(),
  publishedSince: dateTimeNow(),
  publishedUntil: DateTimeService.dateTimeMax,
  startOfVoting: dateTimeNow(),
  endOfVoting: moment().add(7, 'days').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
  displayType: 'numeric', // votes
  template: 'default',
  restrictionType: 'once',
  totalVotes: 0,
  migratedId: '',
  city: '',
  loggedInUsersOnly: true,
  displayResultsBeforeVoting: false,
  enabledVoting: true,
  hidden: false,
  imageInfo: null,
  image: null,
  options: [],
  contestId: null
}
