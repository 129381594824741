import PermissionService from '@/services/PermissionService'

const routes = [
  {
    path: 'hpStatus',
    component: () => import('@/views/hp/vlm/HpStatusView.vue'),
    name: 'hp_status',
    meta: {
      description: 'HP status page',
      requiresAuth: true,
      requiredPermissionModules: PermissionService.REQUIRED_PERMISSIONS.HOMEPAGE_STATUS_PERMISSIONS.listRoute
    }
  }
]

export default routes
